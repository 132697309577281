import React, { useReducer } from 'react'
import ReactDom from 'react-dom'
import CategoryDownloadModal from '../../CategoryDownloadModal'
import Overlay from '../../Overlay'
import { ItemModalContext } from 'scripts/store'
import itemModalReducer, { itemModalState } from 'scripts/item-modal-reducer'

interface CategoryDownloadModalPortalProps {
  resetModal: () => void
}

const CategoryDownloadModalPortal: React.FC<CategoryDownloadModalPortalProps> = ({ resetModal }) => {
  const [itemModalStore, ItemModalDispatch] = useReducer(itemModalReducer, itemModalState)

  return ReactDom.createPortal(
    <ItemModalContext.Provider value={{ ...itemModalStore, dispatch: ItemModalDispatch }}>
      <Overlay resetModal={resetModal} />
      <CategoryDownloadModal resetModal={resetModal} />
    </ItemModalContext.Provider>,
    // @ts-ignore
    document.getElementById('item-modal'),
  )
}
export default CategoryDownloadModalPortal
