import React from 'react'
import ReactDOM from 'react-dom'
import App from './scripts/react/App'
import * as serviceWorker from './serviceWorker'
import { appContextState } from 'scripts/appcontext-reducer'

require('./styles/sass/index.scss')

// Add Class to HTML Tag to choose cholor schema
document.getElementsByTagName('html')[0].classList.add(appContextState.schema);

const rootElement = document.getElementById('root')
ReactDOM.render(<App />, rootElement)

serviceWorker.unregister()
