import React, { useContext } from 'react'
import { AppContext } from 'scripts/store'
import { ActionType } from 'scripts/actions/actions'
import CategoryUpdatedFlag from './updated'

const CategoryItem: React.FC<{ count: number; preview: string; name: string, updated: boolean }> = ({ count, preview, name , updated}) => {
  const { searchField, dispatch } = useContext(AppContext)

  function onItemClick(searchQuery: string) {
    if (searchField.indexOf(searchQuery) < 0) {
      // Reset Searchfield and Place Category alone in it
      dispatch({ type: ActionType.RESET_SEARCH_FIELD_AND_ADD_CATEGORY, item: searchQuery, isCategory: true, categoryName: name })
    }
  }

  return (
    <div
      className="category-box__item"
      onClick={() => {
        onItemClick(name)
      }}>
      <div className="category-box__head">
        <span className="category-box__title">{name}</span>
        <span className="category-box__count">{count} Objects</span>
      </div>
      <span dangerouslySetInnerHTML={{__html: preview}}></span>
      {updated && <CategoryUpdatedFlag label="Updated" />}
    </div>
  )
}
export default CategoryItem
