import React from 'react'

const CategoryDownloadItem: React.FC<{isActive: string, ID: number, name: string; preview: string; count: number; onClick: (id:number, name:string) => void}> = ({isActive, ID, name, preview, count, onClick}) => {
  return(
    <div data-active={isActive} className="category-download-form__category" onClick={() => onClick(ID, name)}>
      <div className="category-download-form__category-title">
        {name}
        <span>{count} Items</span>
      </div>
      <span className="category-download-form__preview" dangerouslySetInnerHTML={{__html: preview}}></span>
    </div>
  )
}

export default CategoryDownloadItem
