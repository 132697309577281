export enum ActionType {
  SET_SEARCH_FIELD,
  RESET_SEARCH_FIELD_AND_ADD_CATEGORY,
  RESET_SEARCH_FIELD,
  DELETE_ITEM_IN_SEARCH_FIELD,
  SET_ACTIVE_DOWNLOAD_FORMAT,
  SET_LOGIN,
  DISPLAY_CATEGORY_DOWNLOAD_MODAL,
}

export type Action =
  | { type: ActionType.SET_SEARCH_FIELD; item: string }
  | { type: ActionType.RESET_SEARCH_FIELD }
  | { type: ActionType.DELETE_ITEM_IN_SEARCH_FIELD; item: string }
  | { type: ActionType.SET_ACTIVE_DOWNLOAD_FORMAT; format: string }
  | { type: ActionType.SET_LOGIN; login: boolean }
  | { type: ActionType.RESET_SEARCH_FIELD_AND_ADD_CATEGORY; item: string, isCategory: boolean, categoryName: string }
  | { type: ActionType.DISPLAY_CATEGORY_DOWNLOAD_MODAL; displayCategoryDownloadModal: boolean }
