import React, { useState, ReactNode, useContext } from 'react'
import CategoryDownloadItem from './category-download-item';
import { ReactNodeArray } from 'prop-types';
import FormatSelector from '../../FormatSelector';
import ColorSelector from '../../ColorSelector';
import Loading from '../../Loading';
import { ItemModalContext } from 'scripts/store';
import CategoryDownloadButton from '../../ActionButton/category-download-button';
import convertAndDownloadCategory from 'scripts/api/download-category-api';

const CategoryDownloadForm: React.FC<{categories: any; colors: any}> = ({categories, colors}) => {
  const [activeColor, setActiveColor] = useState({id: 0, name: ''})
  const [activeDownload, setActiveDownload] = useState(false)
  //const [downloadReady, setDownloadReady] = useState(false)
  const [activeCategory, setActiveCategory] = useState({id: 0, name: ''})
  const { activeDownloadFormat } = useContext(ItemModalContext)

  function onColorClick(id:number, name:string) {
    setActiveColor({id: id, name: name})
  }

  function onCategoryClick(id:number, name:string) {
    setActiveCategory({id: id, name: name})
  }

  let CategoryItem: ReactNodeArray = []
  let ColorItem: ReactNodeArray = []

  if (categories && categories.length > 0) {
    categories.forEach((category: { [key: string]: any }, key: number) => {
        CategoryItem.push(<CategoryDownloadItem
          isActive={activeCategory.id === category.ID ? 'active' : ''}
          ID={category.ID}
          name={category.name}
          preview={category.preview}
          count={category.count}
          onClick={onCategoryClick}
          key={`category-download-item-${key}`}
        />)
    });
  }

  if (colors && colors.length > 0) {
    colors.forEach((color: { [key: string]: any }, key: number) => {
      ColorItem.push(<ColorSelector
        isActive={activeColor.id === color.ID ? 'active' : ''}
        hex={color.hex}
        name={color.name}
        ID={color.ID}
        key={`color-selector-key-${key}`}
        onClick={onColorClick}
      />)
    });
  }

  function onDownloadClick() {
    setActiveDownload(true)

    new Promise(resolve => {
      const downloadFile = convertAndDownloadCategory(activeCategory, activeDownloadFormat, activeColor)
      resolve(downloadFile)
    }).then((response: any) => {
      console.log(response)
      setActiveDownload(false)
    })

  }

  const isDisabled = activeCategory.id !== 0 && activeColor.id !== 0 && activeDownloadFormat !== ''

  const downloadButtonSettings = {
    name: 'downloadCategory',
    className: `button button--blue svgitem-modal__button ${!isDisabled ? 'disabled' : ''}`,
  }

  // If Download is active hide downloadbutton
  const DownloadIndicator: ReactNode = activeDownload ? <Loading /> : null
  const DownloadButton: ReactNode = !activeDownload ? (
    <CategoryDownloadButton label="Download category" settings={downloadButtonSettings} onButtonClick={onDownloadClick} />
  ) : null
  //const DownloadReadyIndicator: ReactNode = !activeDownload && downloadReady ? <> : null

  return (
    <div className="category-download-form">
        <div className="category-download-form__categories">
            <div className="category-download-form__title">Select your category</div>
          {CategoryItem}
        </div>
        <div className="category-download-form__controls">
          <div className="category-download-form__formats">
            <div className="category-download-form__title">Select your format</div>
            <FormatSelector />
          </div>
          <div className="category-download-form__colors">
            <div className="category-download-form__title">Select your color</div>
            {ColorItem}
          </div>
        </div>
        <div className="category-download-form__download">
          {DownloadButton}
          {DownloadIndicator}
        </div>
    </div>
  )
}

export default CategoryDownloadForm
