import React, { ReactNode } from 'react'
import Loading from '../Loading'
import Svg from 'react-inlinesvg'
import { useGetCategories } from 'scripts/api/category-api'
import CategoryDownloadForm from './sub/category-download-form'
import { useGetColors } from 'scripts/api/color-api'

const CategoryDownloadModal: React.FC<{ resetModal: () => void }> = ({ resetModal }) => {
  const [categories, loading] = useGetCategories()
  const [colors, loadingColors] = useGetColors()

  function onClickClose() {
    resetModal()
  }

  let categoryContent: ReactNode
  let loadingModal: ReactNode

  if (!loading && !loadingColors && categories) {
    categoryContent = <CategoryDownloadForm categories={categories} colors={colors} />
  } else {
    loadingModal = <Loading text="loading" />
  }
  return (
    <div className="category-download-modal">
      {loadingModal}
      <span className="category-download-modal__close">
        <Svg src="/svgs/close.svg" onClick={onClickClose} />
      </span>
      {categoryContent}
    </div>
  )
}
export default CategoryDownloadModal
