import React from 'react'

export interface ChildXml {
  activeClass: string
  thumbnail: string
  setNewMaster: (master: { [key: string]: any }) => void
  childXml: { [key: string]: any }
}

const ChildSvg: React.FC<ChildXml> = ({ activeClass, thumbnail, setNewMaster, childXml }) => {
  return (
    <div
      className="svgitem-modal__svg"
      data-class="child"
      data-active={activeClass}
      onClick={() => setNewMaster(childXml)} style={{background: thumbnail}}>

    </div>
  )
}
export default ChildSvg
