import React, { ReactNode, ReactNodeArray, useContext, useState } from 'react'
import Title from './title'
import NewItemIndicator from '../../NewItemIndicator'
import ActiveSvg from './activeSvg'
import ChildSvg from './childSvg'
import Tags from '../../Tags'
import FormatSelector from '../../FormatSelector'
import ActionButton from '../../ActionButton'
import Loading from '../../Loading'
import { ItemModalContext } from 'scripts/store'
import convertAndDownload from 'scripts/api/download-and-convert-api'

const ItemContent: React.FC<{ loading: any; item: any }> = ({ loading, item }) => {
  const { activeDownloadFormat } = useContext(ItemModalContext)
  const [masterXml, setMasterXml] = useState<{ [key: string]: any }>(item.archive.parent)
  const [activeDownload, setActiveDownload] = useState(false)
  const [activeDownloadColor, setActiveDownloadColor] = useState<number>(item.archive.parent.color_id)
  let thumbnail: ReactNodeArray = []

  function clickOnThumbnail(master: { [key: string]: any }): void {
    setMasterXml(master)
    setActiveDownloadColor(master.color_id)
  }

  function onSingleDownload() {
    setActiveDownload(true)
    const activeItem = typeof masterXml.ID !== 'undefined' ? masterXml : item.archive.parent
    const id = activeItem.ID
    const download = new Promise(resolve => {
      const downloadFile = convertAndDownload(id, activeDownloadFormat, activeDownloadColor, activeItem.file_name)
      resolve(downloadFile)
    })

    download.then(() => setActiveDownload(false))
  }

  function onArchiveDownload() {
    setActiveDownload(true)
    const archiveName = item.archive.settings.archive
    const svgs = [item.archive.parent.ID]
    // eslint-disable-next-line
    item.archive.children.map((child: { [key: string]: any }): void => {
      svgs.push(child.ID)
    })

    new Promise(resolve => {
      const activeItem = typeof masterXml.ID !== 'undefined' ? masterXml : item.archive.parent
      const downloadFile = convertAndDownload(activeItem.ID, activeDownloadFormat, activeDownloadColor, archiveName, false)
      resolve(downloadFile)
    }).then(response => {
      setActiveDownload(false)
    })
  }

  item.archive.children.forEach((child: { [key: string]: any }, key: number) => {
    const isActive = masterXml.color_id === child.color_id ? 'is-active' : ''

    thumbnail.push(
      <ChildSvg
        key={`child-svg-${key}`}
        thumbnail={child.thumbnail}
        activeClass={isActive}
        setNewMaster={clickOnThumbnail}
        childXml={child}
      />,
    )
  })

  const singleDownloadButtonSettings = {
    name: 'downloadSingleItem',
    className: 'button button--blue svgitem-modal__button ',
  }

  const archiveDownloadButtonSettings = {
    name: 'downloadArchive',
    className: 'button button--submit svgitem-modal__button ',
  }

  let DownloadIndicator: ReactNode = activeDownload ? <Loading /> : null
  let ActionButtonSingleDownload: ReactNode = !activeDownload ? (
    <ActionButton label="Download item" settings={singleDownloadButtonSettings} onButtonClick={onSingleDownload} />
  ) : null
  let ActionButtonArchiveDownload: ReactNode = !activeDownload ? (
    <ActionButton
      label="Download all colors"
      settings={archiveDownloadButtonSettings}
      onButtonClick={onArchiveDownload}
    />
  ) : null

  return (
    <div className="svgitem-modal__container">
      <div className="svgitem-modal__grid-row">
        <div className="svgitem-modal__side">
          <div className="svgitem-modal__meta">
            <Title title={item.archive.settings.display_name} categories={item.archive.settings.categories} />
            <Tags tags={item.archive.settings.tags} />
          </div>

          <div className="svgitem-modal__actions">
            <div className="svgitem-modal__selectbox">
              <FormatSelector />
            </div>
            <div className="svgitem-modal__buttons">
              {DownloadIndicator}
              {ActionButtonSingleDownload}
              {ActionButtonArchiveDownload}
            </div>
          </div>
        </div>
        <div className="svgitem-modal__side" data-background={!loading && 'show'}>
          {item.archive.settings.is_new && <NewItemIndicator text="New" className="svgitem-modal__new" />}

          <div className="svgitem-modal__xml-container">
            <div className="svgitem-modal__masterxml">
              <ActiveSvg xmlObject={masterXml} />
            </div>
            <div className="svgitem-modal__childxml">{thumbnail}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ItemContent
