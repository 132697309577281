import axios, { AxiosResponse, CancelToken } from 'axios'
import { saveAs } from 'file-saver'
import { appContextState } from 'scripts/appcontext-reducer'

export default async function convertAndDownload(
  id: number,
  format: string,
  colorId: number,
  fileName: string,
  single: boolean = true,
  token?: CancelToken,
) {

  const { tenant, downloadUrl } = appContextState
  const url = `${downloadUrl}/${single ? 'icon' : 'iconarchive'}/?id=${id}&color=${colorId}&format=${format}&tenant=${tenant}`

  return new Promise((resolve: any, reject: any) => {
    const result = axios.get(url, { responseType: 'blob', cancelToken: token })
    result.then((response: AxiosResponse<any>) => {
      format = single ? format : 'zip'
      console.log(format, id)
      resolve(saveAs(url, `${fileName}.${format}`))
    })
  })
}
