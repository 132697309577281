import React from 'react'
import SVG from 'react-inlinesvg'

const CategoryDownloadInfo: React.FC<{ setDisplayCategoryDownloadModal: any }> = ({setDisplayCategoryDownloadModal}) => {

  function onHandleClick(show:boolean) {
    setDisplayCategoryDownloadModal(show)
  }

  return (
    <div className="category-download-info">
        <div className="category-download-info__icon">
            <SVG src="/svgs/info.svg" />
        </div>
        <div className="category-download-info__content">
            <p>You have searched for a Category.<br/>
            If you want to download a complete Package of this category. Follow this <span onClick={() => {onHandleClick(true)}} className="category-download-info__link-to-overlay">link</span> to the Download Basket</p>
        </div>
    </div>
  )
}
export default CategoryDownloadInfo
