import React from 'react'

const ActiveSvg: React.FC<{ xmlObject: { [key: string]: any } }> = ({ xmlObject }) => {
  return (
    <div
      className="svgitem-modal__svg"
      data-class="master"
      style={{color: xmlObject.thumbnail}}
      dangerouslySetInnerHTML={{ __html: JSON.parse(xmlObject.xml) }}></div>
  )
}
export default ActiveSvg
