import axios, { AxiosResponse, CancelToken } from 'axios'
import { saveAs } from 'file-saver'
import { appContextState } from 'scripts/appcontext-reducer'

export default async function convertAndDownloadCategory(
  category: { id: number, name: string},
  format: string,
  color: { id: number, name: string},
  token?: CancelToken,
) {

  const { tenant, downloadUrl } = appContextState
  const url = `${downloadUrl}/iconcategory/?category=${category.id}&color=${color.id}&format=${format}&tenant=${tenant}`

  return new Promise((resolve: any, reject: any) => {
    const result = axios.get(url, { responseType: 'blob', cancelToken: token })
    result.then((response: AxiosResponse<any>) => {
      format = 'zip'
      resolve(saveAs(url, `${category.name}.${format}`))
    })
  })
}
