import React, { ReactNodeArray } from 'react'
import Item from 'scripts/interface/Item.interface'
import ListItem from '../../ListItem'
import PackeryComponent from '../../Packery'
import NoItemsFound from '../../NoItemsFound'
import Loading from '../../Loading'

interface ItemGridProps {
  loading: any
  countItems: any
  searchField: string[]
  listItems: any
  items: any
  setSelectedItem: any
}

const isEqual = (prevProps: Readonly<ItemGridProps>, nextProps: Readonly<ItemGridProps>) => {
  return prevProps.loading === nextProps.loading && prevProps.searchField.length === nextProps.searchField.length
}

const ItemGrid: React.FC<ItemGridProps> = React.memo(({ loading, countItems, listItems, items, setSelectedItem }) => {
  let layout: JSX.Element
  let layoutItems: ReactNodeArray = []

  if (!loading) {
    if (countItems > 0) {
      const packeryOptions = {
        itemSelector: '.svg-grid__item',
        percentPosition: true,
        gutter: 9,
        resize: true,
      }

      listItems.forEach((element: any, key: number) => {
        const itemToPush: Item = items[element][0]
        layoutItems.push(<ListItem handleModal={setSelectedItem} item={itemToPush} key={`svg-${itemToPush.ID}`} />)
      })

      layout = (
        <PackeryComponent className="svg-grid__items" options={packeryOptions}>
          {layoutItems}
        </PackeryComponent>
      )
    } else {
      layout = <NoItemsFound />
    }
  } else {
    layout = <Loading text="Loading objects..." />
  }

  return layout
}, isEqual)

export default ItemGrid
