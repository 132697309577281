import React, { ReactNodeArray } from 'react'
import CategoryItem from './sub'
import FlickityLayout from '../Flickity'
import Loading from '../Loading'
import H2 from '../Headline/h2'
import { useGetCategories } from 'scripts/api/category-api'


export default React.memo(Mycomponent);

function Mycomponent() {
  const [categories, loading] = useGetCategories()

  let layout
  const categoryItems: ReactNodeArray = []

  const flickityOptions = {
    initialIndex: 1,
    freeScroll: true,
    wrapAround: true,
    prevNextButtons: false,
    draggable: false,
    contain: true,
    cellAlign: 'left',
    pageDots: false,
    groupCells: 2,
  }

  if (!loading) {
    categories.forEach((category: { [key: string]: any }, key: number) => {
      categoryItems.push(
        <CategoryItem key={`category-${key}`} name={category.name} preview={category.preview} count={category.count} updated={category.updated} />,
      )
    })

    layout = <FlickityLayout items={categoryItems} flickityOptions={flickityOptions} />
  } else {
    layout = <Loading text="Loading categories..." />
  }

  return (
    <div className="category-container">
      <div className="category-box">
        <H2 text="Categories" />
        {layout}
      </div>
    </div>
  )
}
