import getCached from './cache'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from 'scripts/store'
import { SearchField } from 'scripts/types/appcontext-store'

type Response = [{ [key: string]: any }] | []

export function useGetSvgs(searchField: SearchField, sortBy: string = 'created_at'): [Response, boolean] {
  const [loading, setLoading] = useState<boolean>(true)
  const [response, setResponse] = useState<Response>([])
  const { tenant, envUrl, schema } = useContext(AppContext)

  useEffect(() => {
    setLoading(true)
    let filter = searchField
      .map((text: string) => {
        return `search[]=${text.trim()}`
      })
      .join('&')
    filter = filter.length > 0 ? `&${filter}` : '';

    getCached(`${envUrl}/getitems/?index&sortBy=${sortBy}${filter}&tenant=${tenant}&schema=${schema}`).then(r => {
      setResponse(r.data)
      setLoading(false)
    })
  }, [sortBy, searchField]) // eslint-disable-line

  return [response, loading]
}

export function useGetActiveSvg(id: number) {
  const [loading, setLoading] = useState<boolean>(true)
  const [response, setResponse] = useState<Response>([])
  const { tenant, envUrl, schema } = useContext(AppContext)

  useEffect(() => {
    getCached(`${envUrl}/getitem/?index&id=${id}&tenant=${tenant}&schema=${schema}`).then(r => {
      setResponse(r.data)
      setLoading(false)
    })
  }, [id]) // eslint-disable-line

  return [response, loading]
}
