import React from 'react'

const ColorSelector: React.FC<{isActive: string, hex: string, name: string, ID: number, onClick: (id:number, name:string) => void}> = ({isActive, hex, ID, name, onClick}) => {

  const style: object = {
    background: hex
  }
  return (
    <div className="color-selector" style={style} data-active={isActive} onClick={() => {onClick(ID, name)}}></div>
  );
}

export default ColorSelector
